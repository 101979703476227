<template>
    <div>
        <template v-if="link">
            <span><router-link :to="{ name: 'account-dashboard', params: { accountId: id }}">{{ name }}</router-link></span>
        </template>
        <template v-if="!link">
            <span>{{ name }}</span>
        </template>
        <p class="text-caption">{{ id }}</p>
    </div>
</template>

<script>
export default {

    props: {
        // whether the item should link to the account record; a parent component may set this to false if it just wants the user to select an account (and not navigate to it)
        link: {
            type: Boolean,
            default: false,
        },
        attr: {
            type: Object,
        },
    },

    computed: {
        id() {
            return this.attr.id;
        },
        name() {
            return this.attr.name;
        },
    },

};
</script>
